import * as React from 'react';
import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import Layout from '../components/layout';
import Container from '../components/container';
import Header from '../components/header';
import postForm from '../utils/post-form';
import { SOCIAL_LINKS, SEND_MAIL_URL, EMAIL } from '../constants';
import Link from '../components/link';

const budgets = ['$30,000 and under', '$30,000–50,000', '$50,000–100,000', '$100,000 and up'];

function Price() {
  const [selected, setSelected] = useState(budgets[0]);

  return (
    <RadioGroup value={selected} onChange={setSelected} className="block col-span-2">
      <RadioGroup.Label className="block mb-2 text-lg text-gray-700">
        What have you budgeted for this project?
      </RadioGroup.Label>
      <div className="-space-y-px bg-white rounded-md">
        {budgets.map(budget => (
          <RadioGroup.Option
            key={budget}
            value={budget}
            className="relative flex items-center py-3 cursor-pointer focus:outline-none"
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                    'h-4 w-4 -mt-px cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="flex flex-col ml-3">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-indigo-900' : 'text-gray-900',
                      'block text-base leading-none'
                    )}
                  >
                    {budget}
                  </RadioGroup.Label>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

export default function ContactPage() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleTryAgain() {
    setIsSuccess(false);
    setIsFail(false);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    const values = [...event.target].reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    setIsSubmitting(true);

    postForm(SEND_MAIL_URL, values)
      .then(() => setIsSuccess(true))
      .catch(() => setIsFail(true))
      .finally(() => setIsSubmitting(false));
  }

  return (
    <Layout>
      <Container>
        <Header
          isMain
          className="mt-12 md:mt-20 lg:mt-28"
          h1="Let’s chat"
          text="Tell us about your project, goals, and timeline."
        />

        <div className="grid items-start grid-cols-1 gap-10 mt-12 md:gap-20 lg:grid-cols-2">
          {!isSuccess && !isFail && (
            <form className="space-y-5" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name" className="block text-lg text-gray-700">
                  Your name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="given-name"
                    required
                    className="block w-full px-3 py-1 border-2 border-gray-300 rounded-md sm:text-lg focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-lg text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full px-3 py-1 border-2 border-gray-300 rounded-md sm:text-lg focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-lg text-gray-700">
                  Company
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="organization"
                    required
                    className="block w-full px-3 py-1 border-2 border-gray-300 rounded-md sm:text-lg focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="timeline" className="block text-lg text-gray-700">
                  Do you have a timeline in mind?
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="timeline"
                    id="timeline"
                    autoComplete="tel"
                    aria-describedby="timeline-label"
                    className="block w-full px-3 py-1 border-2 border-gray-300 rounded-md sm:text-lg focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex items-baseline justify-between">
                  <label htmlFor="how-can-we-help" className="block text-lg text-gray-700">
                    How can we help you?
                  </label>
                  <span id="how-can-we-help-description" className="text-base text-gray-500">
                    Max. 500 characters
                  </span>
                </div>
                <div className="mt-1">
                  <textarea
                    id="how-can-we-help"
                    name="howCanWeHelp"
                    aria-describedby="how-can-we-help-description"
                    rows={4}
                    className="block w-full px-3 py-1 border-2 border-gray-300 rounded-md sm:text-lg focus:ring-indigo-500 focus:border-indigo-500"
                    required
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex items-baseline justify-between">
                  <label
                    htmlFor="how-did-you-hear-about-us"
                    className="block text-lg text-gray-700"
                  >
                    How did you hear about us?
                  </label>
                  <span id="how-can-we-help-description" className="text-base text-gray-500">
                    Optional
                  </span>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="howDidYouHearAboutUs"
                    id="how-did-you-hear-about-us"
                    className="block w-full px-3 py-1 border-2 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg"
                  />
                </div>
              </div>

              <Price />

              <div className="mt-4 sm:col-span-2">
                <button
                  type="submit"
                  className="inline-flex justify-center w-full px-10 py-2 text-lg text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {isSubmitting ? 'Sending...' : 'Get in Touch'}
                </button>

                <p className="mt-6 text-lg leading-snug text-gray-500 max-w-[26em]">
                  By filling in the form, you agree to our{' '}
                  <Link
                    className=" transition-colors text-indigo-600 hover:border-indigo-300 border-transparent border-b-[3px] leading-none"
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>
            </form>
          )}

          {isSuccess && (
            <p className="p-8 text-lg rounded-lg bg-indigo-50">
              <span className="" role="img" aria-hidden>
                👋
              </span>{' '}
              Thanks for contacting us. We’ll be in touch soon.
            </p>
          )}

          {isFail && (
            <p className="p-8 text-lg rounded-lg bg-indigo-50">
              <span className="" role="img" aria-hidden>
                😿
              </span>{' '}
              Oops there’s something wrong!{' '}
              <button
                className=" transition-colors text-indigo-600 hover:border-indigo-300 border-transparent border-b-[3px] leading-none"
                type="button"
                onClick={handleTryAgain}
              >
                Try again
              </button>{' '}
              or email us at{' '}
              <a
                className=" transition-colors text-indigo-600 hover:border-indigo-300 border-transparent border-b-[3px] leading-none"
                href={`mailto:${EMAIL}`}
              >
                {EMAIL}
              </a>
            </p>
          )}

          <div className="p-12 space-y-6 text-lg text-gray-600 bg-gray-100 rounded-lg">
            <div>
              <h2 className="text-2xl font-bold text-gray-700">Get in touch</h2>
              <p className="mt-3 text-lg leading-snug ">
                Volti Studio works with a diverse array of clients. Whether your company is small or
                large, near or far, we’d love to hear from you.
              </p>
            </div>

            <div>
              <h3 className="text-base font-bold tracking-wider text-gray-500 uppercase">
                Want to say hello?
              </h3>
              <a
                href="mailto:hello@voltistudio.com"
                className="font-semibold text-indigo-500 transition-colors hover:text-indigo-600 hover:border-indigo-300 border-transparent border-b-[3px] leading-none"
              >
                hello@voltistudio.com
              </a>
            </div>
            <div>
              <h3 className="text-base font-bold tracking-wider text-gray-500 uppercase">
                Want to join us?
              </h3>
              <a
                href="mailto:careers@voltistudio.com"
                className="font-semibold text-indigo-500 transition-colors hover:text-indigo-600 hover:border-indigo-300 border-transparent border-b-[3px] leading-none"
              >
                careers@voltistudio.com
              </a>
            </div>

            <div>
              <h3 className="text-base font-bold tracking-wider text-gray-500 uppercase">
                Address & Phone
              </h3>
              <p className="mt-1">
                Volti Studio, LLC.
                <br />
                30 N Gould St.
                <br /> Ste R Sheridan
                <br />
                WY 82801
              </p>
              <p className="mt-4">+1 (307) 218-3593</p>
            </div>

            <div>
              <h3 className="text-base font-bold tracking-wider text-gray-500 uppercase">Social</h3>

              <ul className="mt-2 space-y-2">
                {SOCIAL_LINKS.map(item => (
                  <li key={item.name}>
                    <Link
                      to={item.to}
                      className="text-indigo-600  hover:border-indigo-300 border-transparent border-b-[3px] leading-none"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
